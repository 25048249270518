import { bind, Subscribe } from '@react-rxjs/core'
import { graphql } from 'gatsby'
import React, { useEffect, useMemo } from 'react'

import useIsSsr from '../../../../hooks/useIsSsr'
import { useResultWithLeftEffect } from '../../../../hooks/useResultWithLeftEffect'
import useSiteSettings, { UseSiteSettings } from '../../../../hooks/useSiteSettings'
import { useSanityRoleAndContextBasedContent } from '../../../../lib/sanity'
import { searchExactQuery } from '../../../../lib/search/exact-query'
import { constNull } from '../../../../utils/constant'
import { filterMenuEntriesToDisplay } from '../../../../utils/sanity/documents/menuEntry'
import MenuEntryLink from '../../../global/header/MenuEntryLink'
import ProductListCard from '../../../products/lists/ProductListCard'
import Spinner from '../../../Spinner'
import * as Styled from './styled'

type RecommendedProducts = NonNullable<
  UseSiteSettings['noSearchResultsFound']
>['recommendedProducts']

const pluckSkusFromProducts = (products: RecommendedProducts): string[] => {
  const skuSet = products?.reduce((acc, product) => {
    if (product?.sku) {
      acc.add(product.sku)
    }
    return acc
  }, new Set<string>())
  return skuSet ? Array.from(skuSet).sort() : []
}

const [useSearchRecommendedProducts] = bind((recommendedProducts: RecommendedProducts) =>
  searchExactQuery({ skus: pluckSkusFromProducts(recommendedProducts) }),
)

const pluckData = <T,>({ data }: { data: T }) => data

const RecommendedProductsList: React.FC<{ recommendedProducts: RecommendedProducts }> = ({
  recommendedProducts,
}) => {
  const [, products] = useResultWithLeftEffect(
    useSearchRecommendedProducts(recommendedProducts),
    pluckData,
    constNull,
  )

  const productsDetails = useMemo(
    () =>
      recommendedProducts
        ?.map((upsellProduct) => {
          return {
            sku: upsellProduct?.sku || '',
            tagline: upsellProduct?.tagline || '',
            shortDescription: upsellProduct?.shortDescription || '',
          }
        })
        .filter(Boolean) || [],
    [recommendedProducts],
  )

  useEffect(() => {
    if (products) {
      const skuOrder = products.map((product) => product?.sku)

      productsDetails.sort((a, b) => {
        return skuOrder.indexOf(a.sku) - skuOrder.indexOf(b.sku)
      })
    }
  }, [products, productsDetails])

  if (!products?.length) {
    return null
  }

  return (
    <>
      <Styled.ItemBoxes>
        {products.map((product, index) => (
          <ProductListCard
            hideFilters
            key={product.sku}
            product={product}
            sanityProductDetails={{
              sku: productsDetails[index].sku,
              tagline: productsDetails[index].tagline,
              shortDescription: productsDetails[index].shortDescription,
            }}
          />
        ))}
      </Styled.ItemBoxes>
    </>
  )
}

export interface NoSearchResultsFoundProps {
  className?: string
}

export const NoSearchResultsFound: React.FC<NoSearchResultsFoundProps> = ({ className }) => {
  const { noSearchResultsFound } = useSiteSettings() || {}
  const { recommendedCategories: categoryEntriesUnfiltered, recommendedProducts } =
    noSearchResultsFound || {}

  const { shouldDisplayContent } = useSanityRoleAndContextBasedContent()
  const categoryEntries = useMemo(
    () => filterMenuEntriesToDisplay(shouldDisplayContent)(categoryEntriesUnfiltered),
    [categoryEntriesUnfiltered, shouldDisplayContent],
  )

  // Suspense not supported server-side yet
  const isSsr = useIsSsr()

  return (
    <Styled.Container className={className}>
      {categoryEntries.length > 0 && (
        <Styled.CategoriesContainer>
          <Styled.CategoriesHeader>Check out these popular categories</Styled.CategoriesHeader>
          <Styled.CategoryList>
            {categoryEntries.map((entry) => (
              <Styled.CategoryItem key={entry.name}>
                <MenuEntryLink
                  contextBasedContent={entry.contextBasedContent}
                  roleBasedContent={entry.roleBasedContent}
                  link={entry.link}
                >
                  {entry.name}
                </MenuEntryLink>
              </Styled.CategoryItem>
            ))}
          </Styled.CategoryList>
        </Styled.CategoriesContainer>
      )}
      {!isSsr && recommendedProducts && (
        <Styled.ProductsContainer>
          <Styled.ProductsHeader>Recommended for you</Styled.ProductsHeader>
          <Subscribe fallback={<Spinner loading />}>
            <RecommendedProductsList recommendedProducts={recommendedProducts} />
          </Subscribe>
          <Styled.ViewProductsLink to={'/products'}>View All Products</Styled.ViewProductsLink>
        </Styled.ProductsContainer>
      )}
    </Styled.Container>
  )
}

export const noSearchResultsFoundFragment = graphql`
  fragment noSearchResultsFound on SanitySiteSettings {
    noSearchResultsFound {
      recommendedCategories {
        ...MenuEntryFragment
      }
      recommendedProducts {
        ...sanityParentProduct
      }
    }
  }
`
