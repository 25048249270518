import styled from 'styled-components'

import MenuEntryLink from '../../../global/header/MenuEntryLink'
import Link from '../../../global/Link'
import LoginLink from '../../../global/LoginLink'
import LogoutLink from '../../../global/LogoutLink'
import RegistrationLink from '../../../global/RegistrationLink'

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.grayscale.white};
`

export const CategoriesHeader = styled.h3`
  font-size: 20px;
  font-family: ${({ theme }) => theme.fontFamilies.gotham};
  font-weight: bold;
  text-align: center;
  padding-bottom: 2rem;
  margin: 0;
`

export const CategoryList = styled.ul`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: auto;
  list-style: none;
  margin: 0 0 1rem 0;
  @media (min-width: 600px) {
    flex-direction: row;
  }
  padding: 0;
`

export const CategoryItem = styled.li`
  display: flex;
  height: 50px;
  align-items: center;
  list-style: none;
  font-family: ${({ theme }) => theme.fontFamilies.robotoCondensed};
  font-size: 14px;
  font-weight: 700;
  margin: 0 10px 0 10px;

  ${MenuEntryLink}, ${LoginLink}, ${RegistrationLink}, ${LogoutLink} {
    color: ${({ theme }) => theme.colors.brand.blue};
    text-transform: uppercase;
    text-decoration: none;
    transition: color 0.15s;
    &:hover {
      text-decoration: underline;
    }
  }

  @media (min-width: 1200px) {
    font-size: 15px;
  }
`

export const ProductsHeader = styled.h3`
  color: ${({ theme }) => theme.colors.coolGray.cool700};
  font-family: ${({ theme }) => theme.fontFamilies.gotham};
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 2rem;
`

export const ItemBoxes = styled.div`
  display: flex;
  gap: 1rem 0.5rem;
  flex-direction: column;
  @media (min-width: 375px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`

export const ProductsContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.grayscale.white};
  font-family: ${({ theme }) => theme.fontFamilies.gotham};
  width: 100%;
  overflow: hidden;
  padding-left: 2.25rem;
  padding-right: 2.25rem;
  @media (min-width: 768px) {
    padding-left: 4.5rem;
    padding-right: 4.5rem;
  }
  @media (min-width: 992px) {
    padding-left: 5.5rem;
    padding-right: 5.5rem;
  }
`

export const ViewProductsLink = styled(Link)`
  display: flex;
  justify-content: center;
  font-family: ${({ theme }) => theme.fontFamilies.robotoCondensed};
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;
  margin-top: 1rem;
  margin-bottom: 3rem;
  color: ${({ theme }) => theme.colors.grayscale.black};
  &:visited {
    color: ${({ theme }) => theme.colors.grayscale.black};
  }
`

export const CategoriesContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.grayscale.white};
  width: 100%;
  overflow: hidden;
  padding: 2.25rem;
  @media (min-width: 768px) {
    padding-left: 4.5rem;
    padding-right: 4.5rem;
  }
  @media (min-width: 992px) {
    padding-left: 5.5rem;
    padding-right: 5.5rem;
  }
`
